<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <validation-observer ref="rule">
        <b-form>
          <b-form-group>
            <label>Mã loại chấn thương<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="code"
              rules="required"
              :custom-messages="code"
            >
              <b-form-input
                v-model="dataInput.code"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label>Tên loại chấn thương<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="name"
            >
              <b-form-input
                v-model="dataInput.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label for="code">Yếu tố chính gây chấn thương<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="type_accident"
              rules="required"
              :custom-messages="type_accident"
            >
              <v-select
                v-model="dataInput.injuryType"
                :reduce="item => item.en"
                label="vi"
                :options="arry || []"
                placeholder="Lựa chọn yếu tố chính gây chấn thương"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetailInjury: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      required,
      code: {
        required: 'Mã số là bắt buộc',
      },
      name: {
        required: 'Tên loại tai nạn là bắt buộc',
      },
      type_accident: {
        required: 'Yếu tố chính gây chấn thương là bắt buộc',
      },
      dataInput: {
        code: '',
        name: '',
        injuryType: '',
      },
      arry: [
        {
          en: 'Head',
          vi: 'Đầu, mặt, cổ',
        },
        {
          en: 'Chest',
          vi: 'Ngực, bụng',
        },
        {
          en: 'Above',
          vi: 'Phần chi trên',
        },
        {
          en: 'Below',
          vi: 'Phần chi dưới',
        },
        {
          en: 'Burn',
          vi: 'Bỏng',
        },
        {
          en: 'Toxic',
          vi: 'Nhiễm độc các chất ở mức độ nặng',
        },
      ],
    }
  },
  watch: {
    dataDetailInjury() {
      if (this.type === 'edit') {
        this.dataInput = {
          code: this.dataDetailInjury.code,
          name: this.dataDetailInjury.name,
          injuryType: this.dataDetailInjury.injuryType,
        }
      }
    },
  },
  methods: {
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    resetData() {
      this.dataInput = {
        code: '',
        name: '',
        injuryType: '',
      }
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
